import React from "react";
import { Toast } from "antd-mobile";
import { getDownloadLink } from "../../api/register";
import image_bg from "../../assets/img/appDownload/bg.png";
import image_iphone from "../../assets/img/appDownload/iphone.png";
import image_android from "../../assets/img/appDownload/android.png";
import styles from "./index.module.less";

interface State {
  androidLink: string;
  iosLink: string;
}

class AppDownload extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      androidLink: "",
      iosLink: "",
    };
  }
  componentDidMount() {
    document.title = "无界时空链";
    this.getDownloadLink();
  }
  getDownloadLink = async () => {
    const res: any = await getDownloadLink();
    if (res.code === 200) {
      this.setState({
        androidLink: res.datas.androidUrl,
        iosLink: res.datas.iosUrl,
      });
    }
  };
  handleDownload = (os: string) => {
    const { androidLink, iosLink } = this.state;
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    const isIOS = Boolean(u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/));
    if (isAndroid) {
      if (os === "android") {
        // const src = "//sns.m.wujieuni.net/backuploads/app/uniapp.apk";
        const form = document.createElement("form");
        form.action = androidLink;
        document.getElementsByTagName("body")[0].appendChild(form);
        form.submit();
      }
      if (os === "iphone") {
        Toast.info("请选择正确的下载按钮");
      }
    }
    if (isIOS) {
      if (os === "android") {
        Toast.info("请选择正确的下载按钮");
      }
      if (os === "iphone") {
        window.location.href = iosLink;
        // Toast.info("暂未开放 敬请期待");
      }
    }
  };
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.buttonGroup}>
          <div className={styles.button}>
            <img
              src={image_android}
              alt=""
              onClick={() => this.handleDownload("android")}
            />
          </div>
          <div className={styles.button}>
            <img
              src={image_iphone}
              alt=""
              onClick={() => this.handleDownload("iphone")}
            />
          </div>
        </div>
        <div className={styles.bg}>
          <img src={image_bg} alt="" />
        </div>
      </div>
    );
  }
}

export default AppDownload;
