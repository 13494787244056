import React from "react";

import CommonFooter from "../../../../components/CommonFooter";
import { Props, State } from "./index.interface";
import styles from "./index.module.less";

class About extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      manager, // 店长
      companyArea, // 地址(省份)
      companyCity, //地址(城市)
      storePhone, // 电话
    } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.textList}>
            <div className={styles.textItem}>
              <span className={styles.label}>店长</span>
              <span className={styles.value}>{manager}</span>
            </div>
            <div className={styles.textItem}>
              <span className={styles.label}>所在地</span>
              <span className={styles.value}>
                {companyArea}
                {companyCity}
              </span>
            </div>
            {/* TODO: 资质 */}
            <div className={styles.textItem}>
              <span className={styles.label}>资质</span>
              <span className={styles.value}>实名认证</span>
              <span className={styles.icon}>></span>
            </div>
            <div className={styles.textItem}>
              <span className={styles.label}>联系电话</span>
              <span className={styles.value}>{storePhone}</span>
            </div>
          </div>
        </div>
        <CommonFooter />
      </div>
    );
  }
}
export default About;
