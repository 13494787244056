import React from "react";
import dsbridge from "dsbridge";
import { Modal, Toast } from "antd-mobile";
import {
  getGoodsCategory,
  addGoodsCategory,
} from "../../../../api/productDetail";

import collect_icon from "../../../../assets/img/collect.png";
import collected_icon from "../../../../assets/img/collected.png";
import v_before from "../../../../assets/img/vbg01.png";
import v_after from "../../../../assets/img/vbg03.png";
import { Props, ComponentState } from "./index.interface";
import styles from "./index.module.less";

class GoodsInfo extends React.Component<Props, ComponentState> {
  timer: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      goodsCategoryList: [], //店铺分类列表
      isShowGoodsCategory: false,
      isShowAdd: false,
      countDown: 0,
    };
    this.timer = null;
  }

  componentDidMount() {
    const { goodsDetail, getGoodsInfo } = this.props;
    const { promotionCountDownTime, promotionState } = goodsDetail;
    this.setState({ countDown: promotionCountDownTime }, () => {
      if (promotionState === 1) {
        this.timer = setInterval(() => {
          const { countDown } = this.state;
          const newCount = countDown - 1;
          this.setState({ countDown: newCount });
          if (newCount < 0) {
            clearInterval(this.timer);
            getGoodsInfo({
              goodsId: 0,
              goodsPrice: goodsDetail.goodsPrice,
              imageSrc: "",
              goodsSpecString: "",
              goodsStorage: 999999,
              vnum: 0,
              promotionState: 0,
            });
          }
        }, 1000);
      }
    });
  }

  componentDidUpdate(prevProps: any) {
    const { goodsDetail, getGoodsInfo } = this.props;
    const { promotionState } = goodsDetail;
    if (promotionState !== prevProps.goodsDetail.promotionState) {
      if (promotionState === 1) {
        this.timer = setInterval(() => {
          const { countDown } = this.state;
          const newCount = countDown - 1;
          this.setState({ countDown: newCount });
          if (newCount < 0) {
            clearInterval(this.timer);
            getGoodsInfo({
              goodsId: 0,
              goodsPrice: goodsDetail.goodsPrice,
              imageSrc: "",
              goodsSpecString: "",
              goodsStorage: 999999,
              vnum: 0,
              promotionState: 0,
            });
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    }
  }

  // static getDerivedStateFromProps(nextProps: any, prevState: any) {
  //   const { goodsDetail } = nextProps;
  //   const { promotionCountDownTime } = goodsDetail;
  //   // 当传入的type发生变化的时候，更新state
  //   if (promotionCountDownTime !== prevState.countDown) {
  //     return {
  //       newCount: promotionCountDownTime,
  //     };
  //   }
  //   // 否则，对于state不进行任何操作
  //   return null;
  // }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  // 获取商品分类
  getGoodsCategory = async () => {
    const res: any = await getGoodsCategory();
    if (res.code === 200) {
      this.setState({
        goodsCategoryList: res.body,
      });
    }
  };

  // 添加商品分类
  addGoodsCategory = async (name: string) => {
    const res: any = await addGoodsCategory({
      name,
    });
    if (res.code === 200) {
      Toast.success("添加成功", 1);
      this.handleShowGoodsCategoryModal();
    }
  };

  // 显示店铺分类弹窗
  handleShowGoodsCategoryModal = () => {
    this.getGoodsCategory();
    this.setState({
      isShowGoodsCategory: true,
    });
  };

  // 关闭店铺分类弹窗
  handleCloseGoodsCategoryModal = () => {
    this.setState({
      isShowGoodsCategory: false,
    });
  };

  // 选择商品分类
  handleSelectGoodsCategory = (id: number) => {
    const { handlePutaway } = this.props;
    this.handleCloseGoodsCategoryModal();
    Modal.alert("上架商品", "确认上架商品到此分类吗？", [
      { text: "取消", onPress: () => this.handleShowGoodsCategoryModal() },
      {
        text: "确定",
        onPress: () => {
          handlePutaway(id);
          this.handleCloseGoodsCategoryModal();
        },
      },
    ]);
  };

  // 显示添加分类弹窗
  handleShowAddModal = () => {
    // this.setState({
    //   isShowAdd: true,
    // });
    this.handleCloseGoodsCategoryModal();
    Modal.prompt(
      "添加分类",
      "",
      [
        {
          text: "取消",
          onPress: value => {
            this.handleShowGoodsCategoryModal();
          },
        },
        {
          text: "确定",
          onPress: value => {
            this.addGoodsCategory(value);
          },
        },
      ],
      "default"
    );
  };

  // 关闭添加分类弹窗
  handleCloseAddModal = () => {
    this.setState({
      isShowAdd: false,
    });
  };

  // 格式化时间
  formatTime = (time: number) => {
    let h = Math.floor(time / 3600);
    let hs = h < 10 ? `0${h}` : h;
    let m = Math.floor((time - h * 3600) / 60);
    let ms = m < 10 ? `0${m}` : m;
    let s = Math.floor(time - h * 3600 - m * 60);
    let ss = s < 10 ? `0${s}` : s;
    return `${hs}:${ms}:${ss}`;
  };

  getPriceInfo = () => {
    const { goodsDetail } = this.props;
    const { countDown } = this.state;
    const {
      promotionState,
      promotionPrice,
      goodsPrice,
      discountPrice,
      inStore,
      promotionType,
    } = goodsDetail;
    if (promotionState === 0) {
      return (
        <div className={styles.info1}>
          <div className={styles.price}>
            <span className={styles.discountPrice}>¥{goodsPrice}</span>
            {/* {goodsPrice !== 0 && (
              <span className={styles.originalPrice}>¥{promotionPrice}</span>
            )} */}
          </div>
          {localStorage.getItem("token") &&
            (inStore ? (
              <span className={`${styles.putaway} ${styles.hasPutaway}`}>
                已上架
              </span>
            ) : (
              <span
                className={styles.putaway}
                onClick={() => {
                  if (!localStorage.getItem("token")) {
                    dsbridge.call("login");
                    return;
                  }
                  this.handleShowGoodsCategoryModal();
                }}
              >
                上架
              </span>
            ))}
        </div>
      );
    }
    return (
      <div className={styles.promotion}>
        <div className={styles.left}>
          <div className={styles.price}>
            <span className={styles.discountPrice}>¥{promotionPrice}</span>
            {goodsPrice !== 0 && (
              <span className={styles.originalPrice}>¥{goodsPrice}</span>
            )}
          </div>
          <div className={styles.count}>
            <span>距结束</span>
            <span>{this.formatTime(countDown)}</span>
          </div>
        </div>
        <span className={styles.promotionText}>
          {promotionType === 1 ? "限时折扣" : "限时秒杀"}
        </span>
      </div>
    );
  };

  render() {
    const {
      goodsDetail,
      isCollect,
      handleChangeFavoriteState,
      isSharePage,
    } = this.props;
    const { isShowGoodsCategory, goodsCategoryList, isShowAdd } = this.state;
    const { vnum, goodsSaleNum, goodsName, secondHand } = goodsDetail;
    return (
      <div className={styles.infoWrap}>
        {/*价格信息 */}
        {this.getPriceInfo()}
        {/*v值 */}
        {vnum > 0 && (
          <span className={styles.vipLevel}>
            <ul className={styles.vbg}>
              <li>
                <img src={v_before} alt="" />
              </li>
              <li className={styles.vbg02}>{vnum}V</li>
              <li>
                <img src={v_after} alt="" />
              </li>
            </ul>
          </span>
        )}
        {/*商品名称 */}
        <div className={styles.info2}>
          <div className={styles.name}>
            {/* {discountPrice !== 0 && <span>秒杀</span>} */}
            {secondHand && <span className={styles.secondHand}>二手</span>}
            {goodsName}
          </div>
          {!isSharePage && (
            <div
              className={styles.favorite}
              onClick={handleChangeFavoriteState}
            >
              <img src={isCollect ? collected_icon : collect_icon} alt="" />
              <span>{isCollect ? "已收藏" : "收藏"}</span>
            </div>
          )}
        </div>
        {/*运费，销量 */}
        <div className={styles.info3}>
          {/* <span>运费：包邮</span> */}
          <span>销量：{goodsSaleNum}</span>
        </div>

        {/*商品分类弹窗 */}
        <Modal
          visible={isShowGoodsCategory}
          transparent
          onClose={this.handleCloseGoodsCategoryModal}
          className={styles.categoryModal}
        >
          <div className={styles.list}>
            {goodsCategoryList.map(item => (
              <div
                className={styles.item}
                key={item.id}
                onClick={() => this.handleSelectGoodsCategory(item.id)}
              >
                <span>{item.name}</span>
                {/* <span>数量:{item.goodsNum}</span> */}
              </div>
            ))}
          </div>
          <div className={styles.add} onClick={this.handleShowAddModal}>
            +添加分类
          </div>
        </Modal>

        {/*添加分类弹窗 */}
        <Modal
          visible={isShowAdd}
          transparent
          onClose={this.handleCloseAddModal}
          className={styles.addModal}
          title="添加分类"
        >
          <div className={styles.add} onClick={this.handleShowAddModal}>
            +添加分类
          </div>
        </Modal>
      </div>
    );
  }
}

export default GoodsInfo;
