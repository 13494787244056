import qs from "qs";

// 获取url参数
export const getParmas = (search: string): object => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  return params;
};

// 比较数组内容是否相同
export const arrEqual = (arr1: number[], arr2: number[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    let flag = false;
    for (let j = 0; j < arr2.length; j++) {
      if (arr1[i] === arr2[j]) {
        flag = true;
        break;
      }
    }
    if (!flag) {
      return false;
    }
  }
  return true;
};

// 判断对象value是否有空
export const isEmpty = (obj: object) => {
  for (let i = 0; i < Object.keys(obj).length; i++) {
    const element = Object.keys(obj)[i];
    if (obj[element] === "") {
      return true;
    }
  }
  return false;
};
