import React from "react";
import classNames from "classnames";
import qs from "qs";
import { Toast } from "antd-mobile";
import { getVStore } from "../../api/store";
import { Props, State } from "./index.interface";
import styles from "./index.module.less";
import Home from "./module/Home";
import Category from "./module/Category";

class VStore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    this.state = {
      activeTab: "home",
      storeId: Number(params.storeId) || 3, // TODO:
      memberId: Number(params.memberId) || 21, // TODO:
      vstore: {
        name: "",
        followNum: 0,
        fansNum: 0,
        avatar: "",
        levelPic: "",
      },
    };
  }
  componentDidMount() {
    if (this.state.storeId && this.state.memberId) {
      this.getData();
    } else {
      Toast.fail("获取失败", 1);
      setTimeout(() => {
        this.props.history.goBack();
      }, 1000);
    }
  }
  getData = async () => {
    const { storeId } = this.state;
    const res: any = await getVStore({ storeId });
    Toast.loading("加载中");

    if (res.code === 200 && res.body) {
      const data = res.body;
      document.title = data.name;
      this.setState({
        vstore: {
          name: data.name,
          followNum: data.followNum,
          fansNum: data.fansNum,
          avatar: data.avatar,
          levelPic: data.levelPic,
        },
      });
    } else {
      Toast.fail("V店信息查询失败", 1);
    }
    Toast.hide();
  };
  handleGoodsItemClick = (commonId: number) => {
    this.props.history.push(`/product_detail?commonId=${commonId}&share=true`);
    window.scrollTo(0, 0);
  };
  handleCategoryItemClick = (category: { id: number; name: string }) => {
    const { memberId } = this.state;
    this.props.history.push(
      `/v_category?categoryId=${category.id}&categoryName=${category.name}&memberId=${memberId}`
    );
  };
  render() {
    const {
      activeTab,
      memberId,
      vstore: { name, followNum, fansNum, avatar, levelPic },
    } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}></div>
          <div className={styles.wrapper}>
            <div className={styles.top}>
              <div className={styles.left}>
                <img src={avatar} alt="头像" />
              </div>
              <div className={styles.right}>
                <div className={styles.storeName}>{name}</div>
                <div className={styles.storeLevel}>
                  <img src={levelPic} alt="信用等级" />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.info}>
                <div className={styles.value}>{followNum}</div>
                <div className={styles.label}>关注</div>
              </div>
              <div className={styles.info}>
                <div className={styles.value}>{fansNum}</div>
                <div className={styles.label}>粉丝</div>
              </div>
            </div>
          </div>
          <div className={styles.tabs}>
            <div
              className={classNames(
                styles.tab,
                activeTab === "home" && styles.activeTab
              )}
              onClick={() =>
                this.setState({
                  activeTab: "home",
                })
              }
            >
              店铺首页
            </div>
            <div
              className={classNames(
                styles.tab,
                activeTab === "category" && styles.activeTab
              )}
              onClick={() =>
                this.setState({
                  activeTab: "category",
                })
              }
            >
              所有分类
            </div>
          </div>
        </div>
        {activeTab === "home" && (
          <Home
            memberId={memberId}
            onGoodsItemClick={(commonId: number) =>
              this.handleGoodsItemClick(commonId)
            }
          />
        )}

        {activeTab === "category" && (
          <Category
            memberId={memberId}
            onCategoryItemClick={(category: { id: number; name: string }) =>
              this.handleCategoryItemClick(category)
            }
          />
        )}
      </div>
    );
  }
}
export default VStore;
