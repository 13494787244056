import { axios_form, axios_json } from "../utils/http";

// 商品信息
export const getDetail = (data: { commonId: number }) => {
  return axios_form({
    url: "/goods",
    data,
    method: "post",
  });
};

// 是否收藏商品
export const getFavoriteState = (data: { commonId: number }) => {
  return axios_form({
    url: "/goods/favorite/member/exist",
    data,
    method: "post",
  });
};

// 商品详情
export const getGoodsDesc = (commonId: number) => {
  return axios_json({
    url: `/goods/extend/${commonId}`,
    method: "get",
  });
};

// 优惠券列表
export const getCouponList = (storeId: number) => {
  return axios_json({
    url: `/v1/store/${storeId}/coupon`,
    method: "get",
  });
};

// 领取优惠券
export const receiveCoupon = (data: { templateId: number }) => {
  return axios_json({
    url: "/v1/store/receive-coupon",
    method: "post",
    data,
  });
};

// 推荐商品
export const getRecommendList = (commonId: number) => {
  return axios_json({
    url: `/v1/goods/${commonId}/recommend`,
    method: "get",
  });
};

// 评价列表
export const getCommentList = (data: {
  page: number;
  evalLv: number | string;
  commonId: number;
}) => {
  return axios_json({
    url: "/goods/evaluate/queryGoodsEvaluate",
    data,
    method: "get",
  });
};

// 收藏商品
export const addCollect = (data: { commonId: number; clientType: string }) => {
  return axios_form({
    url: "/member/goods/favorite/add",
    data,
    method: "post",
  });
};

// 取消收藏商品
export const cancelCollect = (data: { commonId: number }) => {
  return axios_form({
    url: "/member/goods/favorite/delete",
    data,
    method: "post",
  });
};

// 加入购物车
export const addToShoppingCart = (data: {
  buyData: string;
  clientType: string;
  storeId: number;
  cartData: {};
}) => {
  return axios_form({
    url: "/cart/add",
    data,
    method: "post",
  });
};

// 商品上架
export const putaway = (data: { goodsCommonId: number; labelId: number }) => {
  return axios_json({
    url: "/v1/member/store/goods/shelves",
    data,
    method: "post",
  });
};

// 添加浏览历史
export const addHistory = (data: { commonId: number }) => {
  return axios_form({
    url: "/goodsbrowse/add",
    data,
    method: "post",
  });
};

// 获取商品分类
export const getGoodsCategory = () => {
  return axios_json({
    url: "/v1/member/store/label",
  });
};

// 添加商品分类
export const addGoodsCategory = (data: { name: string }) => {
  return axios_json({
    url: "/v1/member/store/label",
    data,
    method: "post",
  });
};

// 获取店铺等级
export const getStoreLevel = (id: number) => {
  return axios_json({
    url: `/v1/store/v2/${id}`,
  });
};
