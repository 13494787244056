import { axios_json } from "../utils/http";

// 注册发送验证码
export const sendCode = (data: { mobile: string }) => {
  return axios_json({
    url: "/v1/login/register/mobile-send-code",
    data,
    method: "post",
  });
};

// 验证注册验证码
export const verifyRegisterCode = (data: { mobile: string; code: string }) => {
  return axios_json({
    url: "/v1/login/register/check-code",
    data,
    method: "post",
  });
};

// 注册
export const register = (data: {
  mobile: string;
  code: string;
  inviteId: string;
  inviteName: string;
  memberName: string;
  mobileCode: string;
  password: string;
}) => {
  return axios_json({
    url: "/v1/login/register",
    data,
    method: "post",
  });
};

// 获取下载链接
export const getDownloadLink = () => {
  return axios_json({
    url: "/app/version",
    method: "get",
  });
};
