import React from "react";
import { Modal, Toast } from "antd-mobile";

import { arrEqual } from "../../../../utils/common";

import close_icon from "../../../../assets/img/close.png";
import add_icon from "../../../../assets/img/add.png";
import delete_icon from "../../../../assets/img/delete.png";
import v_before from "../../../../assets/img/vbg01.png";
import v_after from "../../../../assets/img/vbg03.png";
import { Props, State } from "./index.interface";
import styles from "./index.module.less";

class SpecModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      specIds: [],
      sum: 1,
      currentSpecProductInfo: {
        // 当前规格商品信息
        goodsId: 0,
        goodsPrice: 0,
        imageSrc: "",
        goodsSpecString: "",
        goodsStorage: 999999,
        vnum: 0,
        promotionState: 0,
      },
    };
  }

  componentDidMount() {
    const { specIds } = this.state;
    const { goodsDetail } = this.props;
    // 默认选中第一个
    let newSpecIdArr: number[] = [];
    if (specIds.length === 0) {
      // eslint-disable-next-line array-callback-return
      goodsDetail.goodsSpecValueJson.map((item: any) => {
        if (item.promotionState === 1) {
          newSpecIdArr = item.specValueIds;
        }
      });
      if (newSpecIdArr.length <= 0) {
        // eslint-disable-next-line array-callback-return
        goodsDetail.goodsSpecValueJson.map((item: any) => {
          if (item.isDefault === 1) {
            newSpecIdArr = item.specValueIds;
          }
        });
      }
      if (newSpecIdArr.length <= 0) {
        goodsDetail.specJson.forEach((item, index) => {
          newSpecIdArr[index] = item.specValueList[0].specValueId;
        });
      }
    }
    this.setState(
      {
        specIds: newSpecIdArr,
        currentSpecProductInfo: {
          goodsId: 0,
          goodsPrice: goodsDetail.goodsPrice,
          imageSrc: goodsDetail.imageSrc,
          goodsSpecString: "",
          goodsStorage: 99999,
          vnum: goodsDetail.vnum,
          promotionState: 0,
        },
      },
      () => {
        this.getCurrentSpecProductInfo();
      }
    );
  }

  // 增加数量
  handleAddSum = () => {
    const { sum, currentSpecProductInfo } = this.state;
    if (sum < currentSpecProductInfo.goodsStorage) {
      this.setState({
        sum: sum + 1,
      });
    } else {
      Toast.info("已达库存上限");
    }
  };

  // 减少数量
  handleDeleteSum = () => {
    const { sum } = this.state;
    if (sum > 1) {
      this.setState({
        sum: sum - 1,
      });
    } else {
      Toast.fail("最少购买1件");
    }
  };

  // 规格标签class
  specClass = (id: number, index: number) => {
    const { specIds } = this.state;
    if (id === specIds[index]) {
      return `${styles.specActive}`;
    }
    return "";
  };

  // 选择规格
  handleChangeSpec = (id: number, index: number) => {
    const { specIds } = this.state;
    let newSpecIdArr = [...specIds];
    newSpecIdArr[index] = id;
    this.setState({ specIds: newSpecIdArr }, () => {
      this.getCurrentSpecProductInfo();
    });
  };

  // 获取当前选择规格的商品信息
  getCurrentSpecProductInfo = () => {
    const { specIds, sum } = this.state;
    const { goodsDetail, getGoodsInfo } = this.props;
    goodsDetail.goodsSpecValueJson.forEach(
      (goods: {
        goodsId: number;
        goodsPrice: number;
        imageSrc: string;
        goodsSpecString: string;
        goodsStorage: number;
        specValueIds: number[];
        vnum: number;
        promotionState: number;
        appPrice0: number;
      }) => {
        if (arrEqual(specIds, goods.specValueIds)) {
          if (goods.goodsStorage < sum) {
            this.setState({
              sum: goods.goodsStorage,
            });
          }
          const currentSpecProductInfo = {
            goodsId: goods.goodsId,
            goodsPrice:
              goods.promotionState === 1 ? goods.appPrice0 : goods.goodsPrice,
            imageSrc: goods.imageSrc,
            goodsSpecString: goods.goodsSpecString,
            goodsStorage: goods.goodsStorage,
            vnum: goods.vnum,
            promotionState: goods.promotionState,
          };
          this.setState(
            {
              currentSpecProductInfo,
            },
            () => {
              getGoodsInfo(currentSpecProductInfo);
            }
          );
        }
      }
    );
  };

  // 提交
  handleSubmit = () => {
    const { sum, currentSpecProductInfo } = this.state;
    const { quickBuy, addToShoppingCart, modalType } = this.props;
    if (modalType === "buy") {
      quickBuy(sum, currentSpecProductInfo);
    } else {
      addToShoppingCart(sum, currentSpecProductInfo);
    }
  };

  render() {
    const { sum, specIds, currentSpecProductInfo } = this.state;
    const {
      quickBuy,
      addToShoppingCart,
      showSpecModal,
      handleCloseSpecModal,
      modalType,
      goodsDetail,
    } = this.props;
    return (
      <div className={styles.container}>
        {/*规格弹窗 */}
        <Modal
          popup
          visible={showSpecModal}
          animationType="slide-up"
          onClose={handleCloseSpecModal}
          className={styles.modal}
        >
          <img
            className={styles.close}
            src={close_icon}
            alt=""
            onClick={handleCloseSpecModal}
          />
          <div className={styles.productInfo}>
            <img src={currentSpecProductInfo.imageSrc} alt="" />
            <div className={styles.right}>
              <span className={styles.price}>
                ￥{currentSpecProductInfo.goodsPrice}
              </span>
              {currentSpecProductInfo.vnum > 0 && (
                <span className={styles.vipLevel}>
                  <ul className={styles.vbg}>
                    <li>
                      <img src={v_before} alt="" />
                    </li>
                    <li className={styles.vbg02}>
                      {currentSpecProductInfo.vnum}V
                    </li>
                    <li>
                      <img src={v_after} alt="" />
                    </li>
                  </ul>
                </span>
              )}
              <span className={styles.spec}>
                已选：
                {currentSpecProductInfo.goodsSpecString}
              </span>
            </div>
          </div>

          <div className={styles.specList}>
            {goodsDetail.specJson.map((item, specIndex) => (
              <div className={styles.specItem} key={item.specId}>
                <span>{item.specName}</span>
                <div className={styles.specValueList}>
                  {item.specValueList.map((spec, index) => (
                    <span
                      key={index}
                      className={
                        spec.specValueId === specIds[specIndex]
                          ? styles.specActive
                          : ""
                      }
                      onClick={() =>
                        this.handleChangeSpec(spec.specValueId, specIndex)
                      }
                    >
                      {spec.specValueName}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className={styles.buyNum}>
            <span>购买数量</span>
            <div className={styles.right}>
              <img src={delete_icon} alt="" onClick={this.handleDeleteSum} />
              <span>{sum}</span>
              <img src={add_icon} alt="" onClick={this.handleAddSum} />
            </div>
          </div>

          <div className={styles.buttonList}>
            {modalType === "spec" ? (
              <>
                <span
                  className={styles.shopping}
                  onClick={() => addToShoppingCart(sum, currentSpecProductInfo)}
                >
                  加入购物车
                </span>
                <span
                  className={styles.buy}
                  onClick={() => quickBuy(sum, currentSpecProductInfo)}
                >
                  立即购买
                </span>
              </>
            ) : (
              <span className={styles.confirm} onClick={this.handleSubmit}>
                确定
              </span>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default SpecModal;
