import React from "react";
import qs from "qs";
import { PullToRefresh, Modal } from "antd-mobile";
import CommentItem from "../../components/CommentItem";
import noRecords from "../../assets/img/noRecords.png";

import { getCommentList } from "../../api/productDetail";

import styles from "./index.module.less";

interface State {
  commonId: number;
  evaluateGoodsVoList: {
    evaluateId: number;
    content: string;
    imagesUrlList: string[];
    memberNameHidden: string;
    memberAvatarUrl: string;
    evaluateTime: string;
  }[];
  hasMore: boolean;
  refreshing: boolean;
  currentImg: string;
  showModal: boolean;
}

interface Props {
  [propName: string]: any;
}
let page = 1;
class CommentList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      commonId: 0,
      evaluateGoodsVoList: [],
      hasMore: false,
      refreshing: true,
      currentImg: "",
      showModal: false,
    };
  }

  componentDidMount() {
    document.title = "评价列表";
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    this.setState(
      {
        commonId: Number.parseInt(params.commonId, 10) || 0,
      },
      () => {
        this.getCommentList();
      }
    );
  }

  // 获取评价列表
  getCommentList = async () => {
    const { commonId, evaluateGoodsVoList } = this.state;
    const res: any = await getCommentList({
      commonId,
      evalLv: "",
      page,
    });
    if (res.code === 200) {
      this.setState({
        evaluateGoodsVoList: evaluateGoodsVoList.concat(
          res.datas.evaluateGoodsVoList
        ),
        hasMore: res.datas.pageEntity.hasMore,
      });
    }
  };

  // 关闭图片弹窗
  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  // 打开图片弹窗
  handleShowModal = (url: string) => {
    this.setState({
      showModal: true,
      currentImg: url,
    });
  };

  handleCommentRefresh = () => {
    const { hasMore } = this.state;
    if (hasMore) {
      page++;
      this.getCommentList();
    } else {
      this.setState({ refreshing: false });
    }
  };

  render() {
    const {
      evaluateGoodsVoList,
      refreshing,
      showModal,
      currentImg,
    } = this.state;

    return (
      <div className={styles.container}>
        {evaluateGoodsVoList.length > 0 ? (
          <PullToRefresh
            damping={60}
            distanceToRefresh={0}
            direction="up"
            style={{
              height: "100vh",
              overflow: "auto",
              backgroundColor: "#fff",
            }}
            onRefresh={this.handleCommentRefresh}
            refreshing={refreshing}
            indicator={{
              deactivate: " ",
              finish: " ",
              release: " ",
              activate: " ",
            }}
            prefixCls=""
            getScrollContainer={() => {
              return document.body;
            }}
          >
            {evaluateGoodsVoList.map((item, index) => (
              <div key={index}>
                <CommentItem
                  info={item}
                  isShowTime
                  onClickImg={this.handleShowModal}
                />
              </div>
            ))}
          </PullToRefresh>
        ) : (
          <div className={styles.noRecords}>
            <img src={noRecords} alt="" />
            <span>暂无评论数据</span>
          </div>
        )}

        {/*图片弹窗 */}
        <Modal
          visible={showModal}
          onClose={this.handleCloseModal}
          className={styles.imgModal}
          transparent
        >
          <img
            className={styles.commentImg}
            src={currentImg}
            alt=""
            onClick={this.handleCloseModal}
          />
        </Modal>
      </div>
    );
  }
}

export default CommentList;
