/* eslint-disable jsx-a11y/alt-text */
import React, { SFC } from "react";
import { ComponentProps } from "./index.interface";
import styles from "./index.module.less";
const CommentItem: SFC<ComponentProps> = ({
  isShowTime = false,
  info = {
    evaluateId: 0,
    content: "",
    imagesUrlList: [],
    memberNameHidden: "",
    memberAvatarUrl: "",
    evaluateTime: "",
  },
  onClickImg = () => { },
}) => {
  const Img = React.createRef<HTMLImageElement>();
  return (
    <div className={styles.container}>
      <img
        ref={Img}
        src={info.memberAvatarUrl}
        onError={() => {
          if (Img.current) {
            Img.current.src = require("../../assets/img/img-error.jpg");
          }
        }}
      />
      <div className={styles.info}>
        <span className={styles.name}>{info.memberNameHidden}</span>
        <span className={styles.comment}>{info.content}</span>
        <div className={styles.imgList}>
          {info.imagesUrlList.map((img, index) => (
            <img
              key={index}
              className={styles.commentImg}
              src={img}
              alt=""
              onClick={() => onClickImg(img)}
            />
          ))}
        </div>
        {isShowTime && (
          <span className={styles.time}>发表日期 {info.evaluateTime}</span>
        )}
      </div>
    </div>
  );
};

export default CommentItem;
