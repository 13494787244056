import React from "react";
import qs from "qs";
import back from "../../assets/img/package/back.png";
import package1 from "../../assets/img/package/package1.png";
import package2 from "../../assets/img/package/package2.png";
import package3 from "../../assets/img/package/package3.png";
import { Props } from "./indx.interface";

import styles from "./index.module.less";
class ProductDetail extends React.Component<Props, {}> {
  componentDidMount() {
    document.title = "套餐";
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    localStorage.setItem("token", params.token || "");
  }

  handleToDetail = (id: number) => {
    const { history } = this.props;
    history.push(
      `/product_detail?commonId=${id}&token=${localStorage.getItem("token")}`
    );
  };

  render() {
    const packageList = [
      {
        id: 1527,
        imgUrl: package1,
      },
      {
        id: 1529,
        imgUrl: package2,
      },
      {
        id: 1531,
        imgUrl: package3,
      },
    ];

    return (
      <div className={styles.container}>
        <img src={back} alt="" />
        <div className={styles.packageList}>
          {packageList.map(item => (
            <img
              src={item.imgUrl}
              alt=""
              onClick={() => this.handleToDetail(item.id)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default ProductDetail;
