import React, { SFC } from "react";
import { ComponentProps } from "./index.interface";
import styles from "./index.module.less";

const Tabs: SFC<ComponentProps> = ({
  handleClick = () => {},
  tablist = [],
  activeTab = 0,
}) => {
  return (
    <div className={styles.container}>
      {tablist.map(item => (
        <span
          className={
            item.id === activeTab
              ? `${styles.title} ${styles.active}`
              : styles.title
          }
          onClick={() => handleClick(item.id)}
          key={item.id}
        >
          {item.title}
        </span>
      ))}
    </div>
  );
};

export default Tabs;
