import React, { Fragment } from "react";
import ProductItem from "../../../../components/ProductItem";
import EmptyList from "../../../../components/EmptyList";
import CommonFooter from "../../../../components/CommonFooter";
import { Props, State } from "./index.interface";
import styles from "./index.module.less";

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      storeBannerUrl, // banner
      hotGoods, // 热销
      recommendGoods, // 推荐
      onGoodsItemClick,
    } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.banner}>
            <img src={storeBannerUrl} alt="banner" />
          </div>
          {/* 新品推荐 */}
          <div className={styles.blockTitle}>新品推荐</div>
          {recommendGoods.length ? (
            <div className={styles.recommendList}>
              {recommendGoods.map((goods: any) => {
                return (
                  <Fragment key={goods.commonId}>
                    <ProductItem
                      commonId={goods.commonId}
                      goodsName={goods.goodsName}
                      goodsPrice={goods.goodsPrice}
                      vnum={goods.vnum}
                      imageSrc={goods.imageSrc}
                      // goodsSaleNum={goods.goodsSaleNum} // 不显示
                      // showSaleNum={true} // 不显示
                      // secondhand={goods.secondHand} // 不显示
                      size="small"
                      onClick={onGoodsItemClick}
                    />
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <EmptyList />
          )}

          {/* 店铺热销 */}
          <div className={styles.blockTitle}>店铺热销</div>

          {hotGoods.length ? (
            <div className={styles.hotList}>
              {hotGoods.map((goods: any) => {
                return (
                  <Fragment key={goods.commonId}>
                    <ProductItem
                      commonId={goods.commonId}
                      goodsName={goods.goodsName}
                      goodsPrice={goods.goodsPrice}
                      vnum={goods.vnum}
                      imageSrc={goods.imageSrc}
                      goodsSaleNum={goods.goodsSaleNum}
                      showSaleNum={true}
                      // secondhand={goods.secondHand} // 不显示
                      size="small"
                      onClick={onGoodsItemClick}
                    />
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <EmptyList />
          )}
          <CommonFooter />
        </div>
      </div>
    );
  }
}
export default Home;
