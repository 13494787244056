import React from "react";
import { Toast } from "antd-mobile";
import { getVStoreCategory } from "../../../../api/store";
import EmptyList from "../../../../components/EmptyList";
import CommonFooter from "../../../../components/CommonFooter";
import styles from "./index.module.less";
import { Props, State } from "./index.interface";

class Category extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      list: [],
    };
  }
  componentDidMount() {
    const { memberId } = this.props;
    if (memberId) {
      this.getData();
    }
  }

  getData = async () => {
    const { memberId } = this.props;
    Toast.loading("加载中");
    const res: any = await getVStoreCategory({
      memberId,
    });
    if (res && res.code === 200) {
      this.setState({
        list: res.body,
      });
    }
    Toast.hide();
  };

  render() {
    const { list } = this.state;
    const { onCategoryItemClick } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.main}>
          {list.length ? (
            <div className={styles.textList}>
              {list.map((item: any) => (
                <div
                  key={item.id}
                  className={styles.textItem}
                  onClick={() => onCategoryItemClick(item)}
                >
                  <span className={styles.label}>{item.name}</span>
                  <span className={styles.value}>数量：{item.goodsNum} 件</span>
                </div>
              ))}
            </div>
          ) : (
            <EmptyList />
          )}
        </div>
        <CommonFooter />
      </div>
    );
  }
}
export default Category;
