import qs from "qs";
import { axios_json, axios_form } from "../utils/http";
// 店铺信息
export const getStore = ({ storeId }: { storeId: number }) => {
  return axios_json({
    url: `/v1/store/v2/${storeId}`,
    method: "get",
  });
};

// 店铺商品列表
export const getGoods = (params: {
  storeId: number;
  sort?: string;
  page?: number;
  size?: number;
  goodsName?: string;
}) => {
  const { storeId, ...rest } = params;
  return axios_form({
    url: `/v1/store/${storeId}/goods?${qs.stringify(rest)}`,
    method: "get",
  });
};

// v店信息
export const getVStore = ({ storeId }: { storeId: number }) => {
  return axios_json({
    url: `/v1/store/v/${storeId}`,
    method: "get",
  });
};

// v店商品列表
export const getVStoreGoods = (params: {
  memberId: number;
  page?: number;
  size?: number;
  labelId?: number;
}) => {
  return axios_json({
    url: `/v1/store/v/goods?${qs.stringify(params)}`,
    method: "get",
  });
};

// v店商品列表
export const getVStoreCategory = (params: { memberId: number }) => {
  return axios_json({
    url: `/v1/store/v/label?${qs.stringify(params)}`,
    method: "get",
  });
};
