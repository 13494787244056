import React, { Fragment } from "react";
import { Toast } from "antd-mobile";
import { getVStoreGoods } from "../../../../api/store";
import ProductItem from "../../../../components/ProductItem";
import EmptyList from "../../../../components/EmptyList";
import CommonFooter from "../../../../components/CommonFooter";
import ScrollLoading from "../../../../components/ScrollLoading";
import styles from "./index.module.less";
import { Props, State } from "./index.interface";

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loadingMore: false,
      hasMore: false,
      page: 1,
      size: 30,
      list: [],
    };
  }
  componentDidMount() {
    const { memberId } = this.props;
    if (memberId) {
      this.getData(false);
    }
    document.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    const { loadingMore, hasMore, page } = this.state;
    // TODO: 如果改布局或者滑动区域的话这里应该是要改的..
    if (
      !loadingMore &&
      hasMore &&
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 10
    ) {
      this.setState(
        {
          page: page + 1,
        },
        () => {
          this.getData(true);
        }
      );
    }
  };
  getData = async (isLoadingMore: boolean) => {
    const { page, size, list } = this.state;
    const { memberId } = this.props;
    if (!isLoadingMore) {
      Toast.loading("加载中");
    }
    const res: any = await getVStoreGoods({
      memberId,
      page,
      size,
    });
    if (res && res.code === 200) {
      const { content, last } = res.body;
      if (isLoadingMore) {
        this.setState({
          list: list.concat(content),
          hasMore: !last,
          loadingMore: false,
        });
      } else {
        this.setState({
          list: content,
          hasMore: !last,
          loadingMore: false,
        });
      }
    } else {
      this.setState({
        loadingMore: false,
        hasMore: false,
      });
    }
    if (!isLoadingMore) {
      Toast.hide();
    }
  };

  render() {
    const { list, loadingMore, hasMore } = this.state;
    const { onGoodsItemClick } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.main}>
          {list.length ? (
            <div className={styles.listWrapper}>
              <div className={styles.list}>
                {list.map((goods: any) => {
                  return (
                    <Fragment key={goods.commonId}>
                      <ProductItem
                        commonId={goods.commonId}
                        goodsName={goods.goodsName}
                        goodsPrice={goods.goodsPrice}
                        vnum={goods.vnum}
                        imageSrc={goods.imageSrc}
                        // goodsSaleNum={goods.goodsSaleNum}
                        // showSaleNum={true}
                        secondhand={goods.secondHand}
                        size="large"
                        onClick={onGoodsItemClick}
                      />
                    </Fragment>
                  );
                })}
              </div>
              <ScrollLoading loading={loadingMore} hasMore={hasMore} />
            </div>
          ) : (
            <EmptyList />
          )}
        </div>
        <CommonFooter />
      </div>
    );
  }
}
export default Home;
